import * as React from "react";
import {Form, FormInstance, FormProps, Input, Modal, ModalProps} from "antd";
import { cx, css } from '@emotion/css'
import {SizeType} from "antd/es/config-provider/SizeContext";
import {useEffect, useState} from "react";

const modalCss = css`
  .ant-modal-close-x {
    margin-top: 5px;
    margin-right: 5px;
  }
  .ant-modal-content {
    padding-left: 32px !important;
    padding-right: 32px !important;
    padding-bottom: 32px !important;
  }
  .ant-modal-header, .ant-modal-body, .ant-modal-footer {
    padding-left: 0 !important;;
    padding-right: 0 !important;;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .ant-modal-body {
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .ant-modal-footer {
    padding: 0;
  }
`

const formCss = css`
  .ant-form-item-label {
    
    //margin-left: -14px;
    //padding-left: 14px;
    
    .ant-form-item-required:before {
      margin-left: -12px;
    }
    
    &>label {
      min-width: 54px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC,sans-serif;
      font-weight: 400;
      color: rgba(0,0,0,0.8);
      line-height: 22px;
      
      &:after {
        content: '';
        margin: 0;
      }
    }
  }
`


export type ModalFormProps = {
  onFinish?: (v: any) => Promise<any>
} & Omit<FormProps, "onFinish">

export type FormModalProps = {
  formProps?: ModalFormProps
  onSuccess?: () => void;
} & ModalProps

const FormModal = (props: FormModalProps) => {
  const {
    formProps,
    onSuccess = () => {},
    children,
    ...modalProps
  } = props
  const {onFinish = () => Promise.resolve()} = formProps ?? {}
  const [form] = formProps?.form ? [formProps.form] : Form.useForm();
  const [submitting, setSubmitting] = useState(false)
  return (
    <Modal
      okText='确定'
      cancelText='取消'
      className={cx(modalCss)}
      onOk={form.submit}
      confirmLoading={submitting}
      {...modalProps}
      destroyOnClose
    >
      <Form
        {...{size: "large", ...formProps}}
        form={form}
        preserve={false}
        onFinish={(values: any) => {
          if (submitting) {
            return;
          }
          setSubmitting(true)
          onFinish(values).then(onSuccess).catch(console.error).finally(() => {
            setSubmitting(false)
          });
        }}
        className={cx(formCss)}
      >
        {children}
      </Form>
    </Modal>);
}

export default FormModal
