import * as React from "react";
import {Form, Input, message} from "antd";
import FormModal, {FormModalProps} from "../../modals/formModal";
import styled from "@emotion/styled";
import announceModal from "../../modals/announceModal";
import SmsButton from "../../form/SmsButton";
import {useEffect, useState} from "react";
import jsonRequest from "../../../utils/request/jsonRequest";
import {useSelector} from "react-redux";
import AnnounceDesc from "../../modals/announceModal/AnnounceDesc";

export type PhoneModalProps = {

} & FormModalProps

const ItemGroup = styled.div`
  display: flex;
  gap: 8px;
`

const PhoneModal = (props: PhoneModalProps) => {
  const {
    title = '账号注销',
    onSuccess = () => {},
    ...modalProps
  } = props

  const {account} = useSelector((s: any) => s)

  const handleSubmit = (values) => {
    return jsonRequest("/account/cancel", {
      method: 'post',
      data: values,
    }).then(rsp => {
      if (!rsp.success) {
        message.error(rsp.message)
        return Promise.reject(rsp.message)
      }
    })
  }

  return account && (
    <FormModal
      width={438}
      {...{title, ...modalProps}}
      formProps={{
        onFinish: handleSubmit
      }}
      onSuccess={() => {
        announceModal({
          title: '账号注销',
          type: 'waiting',
          message: '等待系统处理',
          content: (
            <AnnounceDesc>注销成功后短信通知您</AnnounceDesc>
          )
        })
        onSuccess()
      }}
    >
      <Form.Item label='手机号' required={false} rules={[{required: true, message: '请填写手机号'}]}>
        <Input value={account.phone} readOnly />
      </Form.Item>
      <Form.Item label='验证码'>
        <ItemGroup>
          <Form.Item noStyle name='code' rules={[{required: true, message: '请填写验证码'}]}>
            <Input style={{flex: 1}}/>
          </Form.Item>
          <SmsButton url="/account/cancel_sms" phone={account.phone} />
        </ItemGroup>
      </Form.Item>
    </FormModal>
  )
}

export default PhoneModal;
