import * as React from "react";
import {Button, ButtonProps, message} from "antd";
import {useEffect, useState} from "react";
import jsonRequest from "../../utils/request/jsonRequest";

const getCountDownTest = (count) => {
  return `请等待${count}秒`
}

export type SmsButtonProps = {
  text?: string;
  phone: string;
  url?: string;
  method?: 'POST' | 'PUT',
  buttonProps?: ButtonProps,
}

const phoneRegex = /^1\d{10}$/

const SmsButton = (props: SmsButtonProps) => {
  const {
    text = '发送验证码',
    phone,
    url = '/sms',
    method = 'POST',
    buttonProps,
  } = props

  const [sending, setSending] = useState(false)
  const [sand, setSand] = useState(false)
  const [countdownTime, setCountdownTime] = useState(0)

  const handleSend = () => {
    if (!phone) {
      message.error("请输入手机号")
      return ;
    }
    if (phone.match(phoneRegex).length === 0) {
      message.error("手机号格式错误")
      return;
    }
    if (sending || sand) {
      return ;
    }
    setSending(true)
    jsonRequest(url, {
      method,
      data: {
        phone
      }
    }).then((rsp) => {
      if (rsp.success) {
        message.success('发送成功');
        setSand(true);
        setCountdownTime(60)
      } else {
        message.error(rsp.message)
      }
    }).catch(console.log).finally(() => {
      setSending(false)
    })
  }

  useEffect(() => {
    if (sand) {
      let time = 60;
      const interval = setInterval(() => {
        setCountdownTime(time--)
        if (time <= 0) {
          setSand(false)
          clearInterval(interval)
        }
      }, 1000)
      return () => {
        setSand(false)
        clearInterval(interval)
      }
    }
  }, [sand])

  return (
    <Button
      style={{
        width: 120,
        fontSize: 14,
        fontFamily: 'PingFang SC-Regular, PingFang SC',
        color: sand ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.8)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      loading={sending}
      size='large'
      onClick={handleSend}
      {...buttonProps}
    >
      {sending && '发送中'}
      {!sending && sand && getCountDownTest(countdownTime)}
      {!sending && !sand && text}
    </Button>
  )
}

export default SmsButton
