import * as React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  height: 24px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
  line-height: 24px;
  text-align: center;
`

const AnnounceDesc = ({children}) => {
  return (<Container>
    {children}
  </Container>)
}

export default AnnounceDesc
