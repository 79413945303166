import * as React from "react";
import {Modal, ModalFuncProps} from "antd";

import {CheckCircleFilled, CloseCircleFilled, ClockCircleFilled, ExclamationCircleFilled} from "@ant-design/icons"

export type AnnounceModalProps = {
  type?: "success"  | "waiting" | "error" | "warning"| false
  message?: string | false;
} & Omit<ModalFuncProps, "type">

const index = (props: AnnounceModalProps) => {
  const {
    type = false,
    message = false,
    content = null,
    ...modalProps
  } = props
  return Modal.info({
    closable: true,
    wrapClassName: 'announceModal',
    content: (
      <>
        {type && (
          <div className="icon">
            {type == "success" && (
              <CheckCircleFilled className='success' />
            )}
            {type == "error" && (
              <CloseCircleFilled className='error' />
            )}
            {type == "warning" && (
              <ExclamationCircleFilled className='warning' />
            )}
            {type == "waiting" && (
              <ClockCircleFilled className='warning' />
            )}
          </div>
        )}
        {message && (
          <div className='message'>
            {message}
          </div>
        )}
        {content && content}
      </>
    ),
    ...modalProps,
  })
}

export default index
