import * as React from "react";
import {Button, Form, Input, message, Space} from "antd";
import { cx, css } from '@emotion/css'
import FormModal, {FormModalProps} from "../../modals/formModal";
import styled from "@emotion/styled";
import announceModal from "../../modals/announceModal";
import jsonRequest from "../../../utils/request/jsonRequest";
import {useState} from "react";
import SmsButton from "../../form/SmsButton";
import {useSelector} from "react-redux";

const ItemGroup = styled.div`
  display: flex;
  gap: 8px;
`

const PasswordModal = (props: FormModalProps) => {
  const {
    title = '重置登录密码',
    onSuccess = () => {},
    ...modalProps
  } = props

  const {account} = useSelector((s: any) => s)

  const onFinish = (values) => {
    return jsonRequest("/account/password", {
      method: 'PUT',
      data: values,
    }).then((rsp) => {
      if (!rsp.success) {
        message.error(rsp.message)
        return Promise.reject(rsp)
      }
    })
  }
  return account && (
    <FormModal
      width={438}
      {...{title, ...modalProps}}
      formProps={{
        onFinish,
      }}
      onSuccess={() => {
        announceModal({
          title: '登录密码重置',
          type: 'success',
          message: '重置成功',
        })
        onSuccess()
      }}
    >
      <Form.Item
        label='新密码'
        name='password'
        required={false}
        rules={[{required: true, message: '请填写新密码'}]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label='手机号'
        required={false}
        rules={[{required: true, message: '请填写手机号'}]}
      >
        <Input value={account.phone} readOnly />
      </Form.Item>
      <Form.Item label='验证码'>
        <ItemGroup>
          <Form.Item
            noStyle
            name='code'
            rules={[{required: true, message: '请填写验证码'}]}
          >
            <Input style={{flex: 1}}/>
          </Form.Item>
          <SmsButton {...{phone: account.phone, url: '/account/password/sms'}}/>
        </ItemGroup>
      </Form.Item>
    </FormModal>
  )
}

export default PasswordModal;
