import * as React from "react";
import styled from "@emotion/styled";
import {ReactNode} from "react";

const Container = styled.div`
  padding: 0 24px;
  height: 54px;
  display: flex;
  align-items: center;;
  justify-content: space-between;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid rgba(0,0,0,0.12);
`

const Title = styled.div`
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: #000000;
`

export type SettingCardProps = {
  title?: string;
  children?: ReactNode;
}

const SettingCard = (props: SettingCardProps) => {
  const {
    title,
    children,
  } = props
  return (<>
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  </>)
}

export default SettingCard;
