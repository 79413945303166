import * as React from "react";
import ConfirmModal from "../../modals/confirmModal";
import {ContactModalProps} from "../team/ContactModal";
import styled from "@emotion/styled";
import {Checkbox, message} from "antd";
import {useState} from "react";
import {CheckboxChangeEvent} from "antd/es/checkbox";

const CheckText = styled.div`
  margin-top: 26px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
  line-height: 22px;
  
  span {
    padding-right: 0;
  }
  
  a {
    color: #1560FA;
    text-decoration: none;
    line-height: 22px;
  }
`

let checked = false

const CancelAccountConfirm = (props?: ContactModalProps) => {
  const {
    onOk = () => Promise.resolve(),
    ...modalProps
  } = props ?? {};

  checked = false

  const content = (
    <div>
      <span>账号成功注销后，平台会删除您在gitwork.cn平台上的所有数据，并不可以恢复，请务必要仔细阅读注销协议内容！</span>
      <CheckText>
        <Checkbox onChange={e => {
          checked = e.target.checked
        }}>我已阅读并同意</Checkbox><a href='/about?type=agreement&id=22' target='_blank'>《注销协议》</a>
      </CheckText>
    </div>
  )
  return ConfirmModal({
    ...modalProps,
    width: 480,
    title: '账号注销',
    okText: '确认注销',
    okButtonProps: {
      danger: true
    },
    onOk: (e) => {
      console.log(checked)
      if (!checked) {
        message.warning("请同意注销协议")
        return Promise.reject()
      }
      return onOk(e)
    },
    content,
  })
}

export default CancelAccountConfirm
