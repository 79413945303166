import * as React from "react";
import { Form, Input, message} from "antd";
import FormModal, {FormModalProps} from "../../modals/formModal";
import styled from "@emotion/styled";
import announceModal from "../../modals/announceModal";
import {useState} from "react";
import EmailButton from "../../form/EmailButton";
import jsonRequest from "../../../utils/request/jsonRequest";

export type PhoneModalProps = {

} & FormModalProps

const ItemGroup = styled.div`
  display: flex;
  gap: 8px;
`

const EmailModal = (props: PhoneModalProps) => {
  const {
    title = '绑定邮箱地址',
    onSuccess = () => {},
    ...modalProps
  } = props

  const [email, setEmail] = useState()

  const handleFinish = (values) => {
    return jsonRequest("/account/email", {
      method: 'PUT',
      data: values,
    }).then((rsp) => {
      if (!rsp.success) {
        message.error(rsp.message)
        return Promise.reject(rsp)
      }
    })
  }

  return (
    <FormModal
      width={438}
      {...{title, ...modalProps}}
      formProps={{
        onValuesChange: cv => {
          if ('email' in cv) {
            setEmail(cv.email)
          }
        },
        onFinish: handleFinish
      }}
      onSuccess={() => {
        announceModal({
          title: '绑定邮箱地址',
          type: 'success',
          message: '绑定成功',
        })
        onSuccess()
      }}
    >
      <Form.Item
        label='邮箱'
        name='email'
        required={false}
        rules={[{required: true, message: '请填写邮箱'}]}
      >
        <Input />
      </Form.Item>
      <Form.Item label='验证码'>
        <ItemGroup>
          <Form.Item noStyle name='code' required={false} rules={[{required: true, message: '请填写验证码'}]}>
            <Input style={{flex: 1}}/>
          </Form.Item>
          <EmailButton url='/account/email/send' {...{email}}/>
        </ItemGroup>
      </Form.Item>
    </FormModal>
  )
}

export default EmailModal;
