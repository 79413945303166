import * as React from "react";
import PersonalLayout from "../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../components/pages/personal/PersonalBody";
import SettingCard from "../../../components/pages/personal/setting/settingCard";
import {Col, message, Row} from "antd";
import LinkButton from "../../../components/buttons/linkButton";
import auth from "../../../utils/auth";
import {useSelector} from "react-redux";
import announceModal from "../../../components/modals/announceModal";
import PhoneModal from "../../../components/pages/setting/PhoneModal";
import {useState} from "react";
import EmailModal from "../../../components/pages/setting/EmailModal";
import PasswordModal from "../../../components/pages/setting/PasswordModal";
import CancelAccountConfirm from "../../../components/pages/setting/CancelAccountConfirm";
import CancelAccountModal from "../../../components/pages/setting/CancelAccountModal";
import Seo from "../../../components/seo";
import {Link} from "gatsby";
import config from "../../../config";

const PersonalSetting = () => {
  auth.authorize()
  const {account} = useSelector((s: any) => s)
  const [isCancelAccount, setIsCancelAccount] = useState(false)
  const [isUpdatePhone, setIsUpdatePhone] = useState(false)
  const [isUpdateEmail, setIsUpdateEmail] = useState(false)
  const [isUpdatePassword, setIsUpdatePassword] = useState(false)
  return (<>
    <PersonalLayout selected='setting'>
      {account && (
        <PersonalBody
          title='系统设置'
          desc='v1.0'
        >
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <SettingCard title={`ID:${account.id}`}>
                <LinkButton
                  onClick={() => {
                    CancelAccountConfirm({
                      onOk: () => {
                        setIsCancelAccount(true)
                        return Promise.resolve()
                      }
                    })
                  }}
                  danger
                >注销</LinkButton>
              </SettingCard>
            </Col>
            <Col span={8}>
              <SettingCard title='登录密码'>
                <LinkButton onClick={() => setIsUpdatePassword(true)}>重置</LinkButton>
              </SettingCard>
            </Col>
            <Col span={8}>
              <SettingCard title='微信绑定'>
                <LinkButton
                  onClick={() => {
                    message.warning('未开放')
                  }}
                >绑定</LinkButton>
              </SettingCard>
            </Col>
            <Col span={8}>
              <SettingCard title='手机号码'>
                <LinkButton onClick={() => setIsUpdatePhone(true)}>
                  {!account.phone ? '绑定' : '更改'}
                </LinkButton>
              </SettingCard>
            </Col>
            <Col span={8}>
              <SettingCard title='邮箱地址'>
                <LinkButton onClick={() => setIsUpdateEmail(true)}>
                  {!account.email ? '绑定' : '更改'}
                </LinkButton>
              </SettingCard>
            </Col>
            <Col span={8}>
              <SettingCard title='GitHub'>
                <Link
                  className='primary'
                  to={`https://github.com/login/oauth/authorize?client_id=${config.github.clientId}&redirect_uri=${location.origin}/oauth/github`}
                >绑定</Link>
              </SettingCard>
            </Col>
          </Row>
        </PersonalBody>
      )}
    </PersonalLayout>
    <CancelAccountModal
      open={isCancelAccount}
      onSuccess={() => {setIsCancelAccount(false)}}
      onCancel={() => setIsCancelAccount(false)}
    />
    <PhoneModal
      open={isUpdatePhone}
      onSuccess={() => {
        setIsUpdatePhone(false)
      }}
      onCancel={() => setIsUpdatePhone(false)}
    />
    <EmailModal
      open={isUpdateEmail}
      onSuccess={() => setIsUpdateEmail(false)}
      onCancel={() => setIsUpdateEmail(false)}
    />
    <PasswordModal
      open={isUpdatePassword}
      onSuccess={() => setIsUpdatePassword(false)}
      onCancel={() => setIsUpdatePassword(false)}
    />
  </>)
}

export default PersonalSetting;

export const Head = () => <Seo />
