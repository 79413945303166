import * as React from "react";
import styled from "@emotion/styled";
import {ReactNode} from "react";

const Container = styled.span`
  height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: ${(props: any) => props.danger ? '#ff4d4f' : '#1890ff'};
  &:hover {
    cursor: pointer;
  }
`

export type LinkButtonProps = {
  danger?: boolean;
  onClick?: () => void;
  children?: ReactNode;
}

const LinkButton = (props: LinkButtonProps) => {
  const {danger = false, children, ...containerProps} = props
  return (
    <Container {...{danger, ...containerProps}}>
      {children}
    </Container>
  )
}

export default LinkButton
