import * as React from "react";
import {Form, Input, message} from "antd";
import FormModal, {FormModalProps} from "../../modals/formModal";
import styled from "@emotion/styled";
import announceModal from "../../modals/announceModal";
import SmsButton from "../../form/SmsButton";
import {useState} from "react";
import jsonRequest from "../../../utils/request/jsonRequest";

export type PhoneModalProps = {

} & FormModalProps

const ItemGroup = styled.div`
  display: flex;
  gap: 8px;
`

const PhoneModal = (props: PhoneModalProps) => {
  const {
    title = '手机号码变更',
    onSuccess = () => {},
    ...modalProps
  } = props

  const [phone, setPhone] = useState()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = (values) => {
    if (submitting) {
      return;
    }
    setSubmitting(true)
    return jsonRequest("/account/phone", {
      method: 'put',
      data: values,
    }).then(rsp => {
      if (!rsp.success) {
        message.error(rsp.message)
        return Promise.reject(rsp.message)
      }
    }).finally(() => {
      setSubmitting(false)
    })
  }

  return (
    <FormModal
      width={438}
      {...{title, ...modalProps}}
      formProps={{
        onValuesChange: (cv) => {
          if ('phone' in cv) {
            setPhone(cv.phone)
          }
        },
        onFinish: handleSubmit
      }}
      onSuccess={() => {
        announceModal({
          title: '手机号码变更',
          type: 'success',
          message: '重置成功',
        })
        onSuccess()
      }}
    >
      <Form.Item label='手机号' name='phone' required={false} rules={[{required: true, message: '请填写手机号'}]}>
        <Input />
      </Form.Item>
      <Form.Item label='验证码'>
        <ItemGroup>
          <Form.Item noStyle name='code' rules={[{required: true, message: '请填写验证码'}]}>
            <Input style={{flex: 1}}/>
          </Form.Item>
          <SmsButton url="/account/phone_sms" phone={phone} />
        </ItemGroup>
      </Form.Item>
    </FormModal>
  )
}

export default PhoneModal;
